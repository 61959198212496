import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ to, className = "", children, style,externalLink, ...props }) => {
    if(externalLink){
        to = process.env.REACT_APP_HOME_DOMAIN_URL + to;
    }

    return (
        (externalLink) ? (
            <a href={to} className={className} {...props}>
                {children}
            </a>) : (<RouterLink style={style} className={className} to={to}>{children}</RouterLink>)
    );
};

export default Link;
