// index.js
import React from 'react';
import UserStore from '../stores/UserStore';
import { redirectUser, setUser } from '../actions/UserActions';
import Auth from '../services/auth'
import User from '../services/user';
import Home from './home';
import Footer from './footer';
import {withRouter} from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import ReactGA from "react-ga4";
import Link from './Link/Link';
import Head from './head';
import logo from '../../src/img/logo.svg'

class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {...this.getState(), isOpenDrawer: false};
    this.onChange = this.onChange.bind(this);
    this.onTopbarChange = this.onTopbarChange.bind(this);
    this.onFooterDisclaimerChange = this.onFooterDisclaimerChange.bind(this);
    this.navBar = this.navBar.bind(this);
    this.logout = this.logout.bind(this);
    User.getTopbar();
    User.getDisclaimer();
  }


  // Check if Token is already set
  componentWillMount() {
    const token = localStorage.getItem('token');
    if (token) {
      Auth.checkAuth().then(() => {
        User.getCreditsAndVouchers().then(() => {}).catch(err => {
        });
        redirectUser(token, this.props.location.pathname, this.props.location.query);
        this.onChange();
      });
    }

    this.setState({isOpenDrawer: false});
  }

  componentDidMount() {
    UserStore.addChangeListener(this.onChange);
    UserStore.addTopbarListener(this.onTopbarChange);
    UserStore.addFooterDisclaimerListener(this.onFooterDisclaimerChange);
    // Smooth scrolling using jQuery easing
    window.$('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
      if (window.location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && window.location.hostname == this.hostname) {
        var target = window.$(this.hash);
        target = target.length ? target : window.$('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          window.$('html, body').animate({
            scrollTop: (target.offset().top - 70)
          }, 1000, 'easeInOutExpo"');
          return false;
        }
      }
    });

    // Scroll to top button appear
    window.$(document).scroll(function() {
      var scrollDistance = window.$(this).scrollTop();
      if (scrollDistance > 100) {
        window.$('.scroll-to-top').fadeIn();
      } else {
        window.$('.scroll-to-top').fadeOut();
      }
    });

    // Closes responsive menu when a scroll trigger link is clicked
    window.$('.js-scroll-trigger').click(function() {
      window.$('.navbar-collapse').collapse('hide');
    });

    // Activate scrollspy to add active class to navbar items on scroll
    window.$('body').scrollspy({
      target: '.navbar',
      offset: 80
    });

    // Collapse Navbar
    var navbarCollapse = function() {
      if(window.$('.navbar').length && window.location.pathname !== '/dashboard') {
        if (window.$('.navbar').offset().top > 100) {
          window.$('.navbar').addClass('navbar-change');
        } else {
          window.$('.navbar').removeClass('navbar-change');
        }
      }
    };
    // Collapse now if page is not at top
    navbarCollapse();
    // Collapse the navbar when page is scrolled
    window.$(window).scroll(navbarCollapse);

    // Modal popupwindow.$(function () {
    // window.$('.portfolio-item').magnificPopup({
    //   type: 'inline',
    //   preloader: false,
    //   focus: '#username',
    //   modal: true
    // });
    // window.$(document).on('click', '.portfolio-modal-dismiss', function(e) {
    //   e.preventDefault();
    //   window.$.magnificPopup.close();
    // });

    // Floating label headings for the contact form
    window.$(function() {
      window.$('body').on('input propertychange', '.floating-label-form-group', function(e) {
        window.$(this).toggleClass('floating-label-form-group-with-value', !!window.$(e.target).val());
      }).on('focus', '.floating-label-form-group', function() {
        window.$(this).addClass('floating-label-form-group-with-focus');
      }).on('blur', '.floating-label-form-group', function() {
        window.$(this).removeClass('floating-label-form-group-with-focus');
      });
    });

    window.$(document).on('click','.navbar-collapse.show',function(e) {
      if( window.$(e.target).is('a:not(".dropdown-toggle")') ) {
        window.$(this).collapse('hide');
      }
    });
  }


  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.trackPageview();
    }
    if (this.props.location !== prevProps.location) {
      this.setState({ isOpenDrawer: false });
    }
  }

  trackPageview() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title:  window.location.pathname});
  }


  logout(){
    window.location =  process.env.REACT_APP_HOME_DOMAIN_URL;
    Auth.logout();
  }

  getState() {
    if(localStorage.getItem('token') && !UserStore.getLoggedIn()) {
      setUser(localStorage.getItem('token'));
    }
    return {
      isLoggedIn: UserStore.getLoggedIn(),
      isOpen: false,
      user: UserStore.getUser()
    };
  }

  onChange(){
    this.setState(this.getState());
  }

  onTopbarChange(){
    this.setState({topbar: UserStore.getTopbar()});
  }
  onFooterDisclaimerChange(){

    this.setState({footerDisclaimer: UserStore.getFooterDisclaimer()});
    
  }

  closeTopbar() {
    this.setState({topbar: null});
  }

  toggleSideBar(){
    var element = document.getElementsByTagName('html')[0];
    element.classList.toggle('close-sidebar');
  }

   wrapPhoneNumbersInAnchors(text) {
    const phoneRegex = /(\b\d{3}[-.\s]?\d{3}[-.\s]?\d{4}\b)/g;
    const replacedText = text.replace(phoneRegex, '<a href="tel:+1$1">$1</a>');
    return replacedText;
  }

  openDrawer(){
    this.setState((state)=>{
        return {isOpenDrawer: !state.isOpenDrawer}
        });
  }

  navBar() {
    return (
    <div className='sticky'>
      <Head title={"Booking - Tripper Bus"}/>
      <nav className={"navbar navbar-expand-xl" + (window.location.pathname === '/dashboard' ? ' navbar-change' : '')}>
        <div className="container">
        {/* <button className="navbar-toggler" type="button" onClick={()=>this.openDrawer()}>
            <i className="fa fa-bars"></i>
          </button> */}
          <div>
            {!this.state.isOpenDrawer ? <img className="navbar-toggle-bar" src="https://new.tripperbus.dev/_next/image?url=%2Fheader%2Fdrawer.webp&w=48&q=75" height={24} width={24} alt="toggle icon" onClick={()=>this.openDrawer()}/> :
            <img className="navbar-toggle-bar" src="https://new.tripperbus.dev/header/close_white.svg" height={24} width={24} alt="toggle icon"  onClick={()=>this.openDrawer()}/>}
            
            <a className="navbar-brand" href={process.env.REACT_APP_HOME_DOMAIN_URL} >
              <img src={logo} height={40} width={145} alt="logo" />
          </a>
         </div>
         {!this.state.isLoggedIn && <li id="menu-item-78" className="nav-item menu-item only-mobile">
                <Link className="login-nav-link" title="Log In" to="/login">LOG IN</Link>
              </li>}
         {this.state.isLoggedIn && <li id="menu-item-78" className="nav-item menu-item only-mobile">
                <button onClick={this.logout.bind(this)} className="login-nav-link">Logout</button>
              </li>}

          {<div id="main-nav">
            <ul id="menu-main-menu" className={`${this.state.isOpenDrawer ? 'drawer' : 'drawer-close'} navbar-nav`}>
              <div className='innerContent'>
              <li id="menu-item-15" className={'nav-item menu-item' + (window.location.pathname === '/' ? ' active' : '')}>
                <Link title="Book" to="" className="nav-link">Book</Link>
              </li>
              <li id="menu-item-16" className="nav-item menu-item">
                <Link title="Locations" to={'/locations'}className="nav-link" externalLink={true}>Locations</Link>
              </li>
              <li id="menu-item-18" className="nav-item menu-item">
                <Link title="Elite Bus Service" to="/elite-bus-service/" className="nav-link" externalLink={true}>Elite Bus</Link>
              </li>
              <li id="menu-item-20" className="nav-item menu-item dropdown">
                  <Link title="FAQs" to="/faqs" className="nav-link" externalLink={true}>FAQs</Link>    
              </li>
              {/* <li id="menu-item-20" className="nav-item menu-item dropdown">
                  <Link title="Call Now" to="+1 (718) 834-9214"
                  className="nav-link only-mobile orange" externalLink={true}>Call Now</Link>    
              </li> */}
              <li id="menu-item-77" className={'nav-item menu-item only-mobile' + (window.location.pathname === '/edit-trip' ? ' active' : '')}>
                <Link title="Edit Ticket" to="/edit-trip" className="nav-link">Edit Ticket</Link>
              </li>
              {this.state.isLoggedIn && <li id="menu-item-78" className={'nav-item menu-item only-mobile dashboard' + (window.location.pathname === '/dashboard' ? ' active' : '')}>
                <Link to="/dashboard" className="nav-link">My Dashboard</Link>
              </li>}
              </div>
              <div className='rewardDetails'>
                <img src="https://tripperbus.com/_next/image?url=%2Ffaqs%2Fearly_points%2Flogo_start.png&w=128&q=75" width={50} height={30} alt="tripper bus rewards logo"/>
                <div>
                  <p className='head'>Earn points towards a free trip.</p>
                  <span>Earn points for a free trip on both our standard buses and the luxury <b>'Tripper Elite '</b> coach <a href="" _target="blank">Learn more about rewards <img src="https://tripperbus.com/header/right_arrow.svg" alt='arrow' width={'8px'} height={'8px'}/></a></span>
                </div>
              </div>
            </ul>


            <ul id="desktop-nav" className={`only-desktop`}>
              <div className='innerContent'>
              <li id="menu-item-15" className={'nav-item menu-item' + (window.location.pathname === '/' ? ' active' : '')}>
                <Link title="Book" to="" className="nav-link">Book</Link>
              </li>
              <li id="menu-item-16" className="nav-item menu-item">
                <Link title="Locations" to={'/locations'}className="nav-link" externalLink={true}>Locations</Link>
              </li>
              <li id="menu-item-18" className="nav-item menu-item">
                <Link title="Elite Bus Service" to="/elite-bus-service/" className="nav-link" externalLink={true}>Elite Bus</Link>
              </li>
              <li id="menu-item-20" className="nav-item menu-item dropdown">
                  <Link title="FAQs" to="/faqs" className="nav-link FAQ" externalLink={true}>FAQs</Link>    
              </li>
              </div>
            </ul>
            
          </div>}
    

          <div id="secondary-nav" className="collapse navbar-collapse justify-content-end">
            <ul id="menu-secondary-menu" className="navbar-nav">
              <li id="menu-item-77" className={'nav-item menu-item' + (window.location.pathname === '/edit-trip' ? ' active' : '')}>
                <Link title="Edit Ticket" to="/edit-trip" className="nav-link">Edit Ticket</Link>
              </li>
              {!this.state.isLoggedIn && <li id="menu-item-78" className="nav-item menu-item">
                <Link title="Log In" to="/login" className="loginBtn">Log In</Link>
              </li>}
              {this.state.isLoggedIn && <li id="menu-item-78"  className={'nav-item menu-item' + (window.location.pathname === '/dashboard' ? ' active' : '')}>
                <Link to="/dashboard" className="nav-link">My Dashboard</Link>
              </li>}
              {this.state.isLoggedIn && <li id="menu-item-78" className="nav-item menu-item">
                <button onClick={this.logout.bind(this)} className="loginBtn">Logout</button>
              </li>}
            </ul>
          </div>
        </div>
      </nav>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.props.location.pathname !== '/login' && this.props.location.pathname !== '/registration' && this.props.location.pathname !== '/forgot-password' && this.props.location.pathname.indexOf('reset-password') === -1 && this.navBar()}
        {this.state.topbar && this.state.topbar.visible && <div className="notification-bar alert alert-dismissible fade show" role="alert">
          <div className="container">
            <strong>{this.state.topbar.title}</strong> {this.state.topbar.link ? <a className="top-link" href={this.state.topbar.link} target="_blank"><span className='phone'  dangerouslySetInnerHTML={{__html: this.wrapPhoneNumbersInAnchors(this.state.topbar.text)}}></span></a> : <span className='phone'  dangerouslySetInnerHTML={{__html: this.wrapPhoneNumbersInAnchors(this.state.topbar.text)}}></span> }
            <button type="button" className="close notification-close" data-dismiss="alert" aria-label="Close" onClick={this.closeTopbar.bind(this)}>
              <span><i className="fa fa-times"></i></span>
            </button>
          </div>
        </div>}
        <div className={this.state.topbar ? 'topAlertPadding' : ''}>
          {this.props.children || <Home></Home>}
        </div>
        {this.props.location.pathname !== '/login' && this.props.location.pathname !== '/registration' && this.props.location.pathname !== '/forgot-password' && this.props.location.pathname.indexOf('reset-password') === -1  &&<Footer disclaimer={this.state.footerDisclaimer}></Footer>}
      </div>
    );
  }
}

export default withRouter(App);
